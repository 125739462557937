import access from "./access";
import accounts from "./accounts";
import address_districts from "./address_districts";
import address_regions from "./address_regions";
import address_village_councils from "./address_village_councils";
import address_locality from "./address_locality";
import address_street from "./address_street";
import api_test from "./api_test";
import banks from "./banks";
import docs from "./docs";
import connected_equipment from "./connected_equipment";
import contacts from "./contacts";
import inspections from "./inspections";
import rehab1 from "./rehab1";
import rehab2 from "./rehab2";
import rehab3 from "./rehab3";
import mdrb from "./mdrb";
import contacts_types from "./contacts_types";
import contr from "./contr";
import contr_dep from "./contr_dep";
import posts from "./posts";
import posts_wards from "./posts_wards";
import country from "./country";
import demo from "./demo";
import divisions_mvd from "./divisions_mvd";
import document_types from "./document_types";
import employees from "./employees";
import files from "./files";
import files_versions from "./files_versions";
import fizlica from "./fizlica";
import history_changes from "./history_changes";
import icd10 from "./icd10";
import icf from "./icf";
import spr_analis from "./spr_analis";
import spr_aes_group from "./spr_aes_group";
import mailing from "./mailing";
import messages from "./messages";
import nomenclature from "./nomenclature";
import numerators from "./numerators";
import offline from "./offline";
import patients from "./patients";
import positions from "./positions";
import publications from "./publications";
import roles from "./roles";
import templates from "./templates";
import test from "./test";
import units from "./units";
import users from "./users";
import users_roles from "./users_roles";
import injections from "./injections";
import release_form from "./release_form";
import medicaments from "./medicaments";
import webinars from "./webinars";
import patients_cards from "./patients_cards";

import { deepClone, deepMerge } from "@/helpers/utils";

const tables: any = {
	access,
	accounts,
	address_districts,
	address_regions,
	address_village_councils,
	address_locality,
	address_street,
	api_test,
	banks,
	docs,
	connected_equipment,
	contacts,
	inspections,
	rehab1,
	rehab2,
	rehab3,
	mdrb,
	contacts_types,
	contr,
	'contr.dep': contr_dep,
	posts,
	'posts.wards': posts_wards,
	country,
	demo,
	divisions_mvd,
	document_types,
	employees,
	files,
	files_versions,
	fizlica,
	history_changes,
	icd10,
	icf,
	spr_analis,
	spr_aes_group,
	mailing,
	messages,
	nomenclature,
	numerators,
	offline,
	patients,
	positions,
	publications,
	roles,
	templates,
	test,
	units,
	users,
	'users.roles': users_roles,
	injections,
	release_form,
	medicaments,
	webinars,
	patients_cards
}

export const schema: any = {};

export const loadSchema = (dbSchema: any) => {
	for (const key in schema) delete schema[key];

	for (const key in dbSchema) {
		schema[key] = deepMerge(dbSchema[key], tables[key] ? tables[key] : {});
	}

	// console.log(schema);
}

export const getModel = (name: string) => {
	const model = schema[name];

	if (model) {
		const _model = deepClone(model);

		//Если напрямую запрашивается модель табличной части, то удаляем признак "subtable"
		if (name.split('.').length > 1 && _model.subtable) delete _model.subtable;

		return _model;
	} else {
		return tables[name] ? deepMerge({ offLine: true }, tables[name]) : { offLine: true };
	}
}
